<template>
  <div class="login">
    <div class="login-box">
      <div class="login-box-title">管理系统</div>
      <el-input v-model="username" placeholder="用户名" class="account" />
      <el-input class="password" v-model="password" placeholder="密码" show-password @keyup.enter.native="Login" />
      <el-button type="primary" @click="Login" :loading="loading">登录</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { post } from 'api/request'
import axios from 'axios'

export default {
  setup() {
    const router = useRouter()
    const data = reactive({
      username: '',
      password: '',
      loading: false
    })

    async function Login() {
      if(!data.username || !data.password) {
        return ElMessage({ type: 'warning', message: '请输入用户名及密码' })
      }
      data.loading = true
      await post('/api/auth/login', { username: data.username, password: data.password }).then((res) => {
        if (res.access_token) {
          const token = res.token_type + ' ' + res.access_token
          localStorage.setItem('token', token)
          localStorage.setItem('saveTime', Date.now()) // 存储时间
          localStorage.setItem('expires_in', (res.expires_in - 10) * 1000) // 失效时间

          if(res.user.role_name == '销售主账号') {
            localStorage.setItem('roleName', '销售')
            localStorage.setItem('roleNamex', '销售x')
          } else {
            localStorage.setItem('roleName', res.user.role_name)
            localStorage.setItem('roleNamex', '')
          }
          localStorage.setItem('userName', data.username)
          router.replace('/home/index')
        }
        setTimeout(() => {
          data.loading = false
        }, 1000)
      }).catch(() => {
        data.loading = false
      })
    }

    function parseToken(token) {
      axios.get(`/api/authInfo`, {
        headers: {
            'Authorization': token,
            'content-type': 'application/json'
        }
      }).then((res) => {
        const token = res.data.token_type + ' ' + res.data.access_token
        localStorage.setItem('token', token)
        localStorage.setItem('saveTime', Date.now()) // 存储时间
        localStorage.setItem('expires_in', (res.data.expires_in - 10) * 1000) // 失效时间

        if(res.data.user.role_name == '销售主账号') {
          localStorage.setItem('roleName', '销售')
          localStorage.setItem('roleNamex', '销售x')
        } else {
          localStorage.setItem('roleName', res.data.user.role_name)
          localStorage.setItem('roleNamex', '')
        }
        localStorage.setItem('userName', res.data.user.username)
        router.replace('/home/index')
      })
    }

    const route = useRoute()

    onMounted(() => {
      if(route.query.token) {
        parseToken(route.query.token)
      } else {
        let token = localStorage.getItem('token')
        let saveTime = localStorage.getItem('saveTime')
        let expires_in = localStorage.getItem('expires_in')
        if(token && saveTime && expires_in) {
          if((Date.now() > saveTime + expires_in)) {
            localStorage.removeItem('token')
            localStorage.removeItem('saveTime')
            localStorage.removeItem('expires_in')
            router.replace({ path: '/login' })
          } else {
            goToPage()
          }
        } else {
          router.replace({ path: '/login' })
        }
      }
    })

    function goToPage() {
      router.replace({ path: '/home/index' })
    }

    return {
      ...toRefs(data),
      Login,
      goToPage,
      parseToken
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  &-box {
    width: 300px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-30%, -50%);
    display: flex;
    flex-direction: column;
    &-title {
      text-align: center;
      font-size: 25px;
      margin-bottom: 30px;
    }
    .el-input {
      margin-bottom: 20px;
    }
  }
}
.wechat{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .img{
    width: 30px;
    cursor: pointer;
  }
}
</style>